/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.9rem 1.2rem;
  transition: $global-transition;
  border-radius: $global-radius;

  @include smallcaps();
}

/* Style variants
   ========================================================================== */

.c-btn--purple {
  border: 1px solid $color-purple-dark;
  background-color: transparent;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-purple-dark;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
  }
}

.c-btn--faq {
  display: block;
  margin: $spacing auto 0;
  text-align: center;

  &:hover {
    color: $color-purple-dark;
  }
}

/* Buttons section
   ========================================================================== */

.c-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  * + & {
    margin-top: $column-spacing;
  }

  .c-btn {
    width: fit-content;
  }
}
