/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1,
h2,
h3 {
  font-family: $font-serif;
  font-weight: 700;

  em {
    font-style: italic;
    font-weight: 300;
    font-size: 0.625em;
    line-height: 0.9;
  }
}

h1 {
  font-size: 3.6rem;
  font-size: clamp(2.2rem, 0.125vw + 1.6rem, 3.6rem);
}

h2 {
  font-size: 3rem;
  font-size: clamp(1.8rem, 1.8vw + 1.4rem, 3rem);
}

h3 {
  font-size: 2.44rem;
  font-size: clamp(1.5rem, 1.7vw + 1rem, 2.44rem);
}

h4,
h5,
h6 {
  font-weight: inherit;
  font-size: 1rem;
}

.c-section-heading {
  margin-bottom: $column-spacing * 2;
}
