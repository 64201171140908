// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 0 !default;
$global-transition: all 500ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'Hanken Grotesk', -apple-system, system-ui, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;

$font-serif: 'Cormorant', 'Georgia', 'Times New Roman', serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1400px,
  full: 1600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #1e1e1e;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

$color-light-cream: #fcf7f2;
$color-dark-cream: rgba(237, 201, 169, 0.25);
$color-purple: #b7a1e7;
$color-purple-dark: #510c76;

// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $color-black;
$color-hover: $color-white;

// Borders
$color-border: $color-purple;

// Fills
$color-fill: $color-very-light-grey;

/* CSS variables
   ========================================================================== */

:root {
  --section-spacing: 1.5rem;

  @media (min-width: map-get($breakpoints, 'medium')) {
    --section-spacing: 3rem;
  }

  @media (min-width: map-get($breakpoints, 'large')) {
    --section-spacing: 5rem;
  }
}

$spacing: 1rem;
$column-spacing: 2vw;
$row-spacing: 2vh;
