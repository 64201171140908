.c-ambassadors {
  .o-section__title {
    margin-bottom: var(--section-spacing);
    text-align: center;
    line-height: 1;
  }
}

.c-ambassador {
  position: relative;
  width: 100%;
  transition: $global-transition;
  background-color: $color-dark-cream;

  @include bp(large) {
    min-height: 280px;
  }

  * {
    transition: $global-transition;
  }
}

.c-ambassador__cover {
  width: 100%;

  @include bp(medium) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .c-ambassador[aria-expanded='true'] & {
    @include bp-down(medium) {
      height: 0;
    }
  }

  .o-picture__img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: $global-transition;
  }
}

.c-ambassador__image {
  position: relative;
  align-self: stretch;
}

.c-ambassador__inner {
  position: relative;
  z-index: 1;

  @include bp(medium) {
    padding-top: var(--section-spacing);
    padding-bottom: var(--section-spacing);
  }
}

.c-ambassador__heading {
  position: relative;
  text-align: left;
}

.c-ambassador__title {
  font-style: italic;
}

.c-ambassador__name {
  font-family: $font-serif;

  @include bp-down(medium) {
    font-size: 1.25rem;
  }

  @include bp(medium) {
    font-size: 1.5rem;
  }
}

@include bp(medium) {
  .c-ambassador:nth-child(odd) {
    .c-ambassador__inner {
      grid-column: 1 / 5;
      padding-left: $column-spacing;
    }

    .c-ambassador__image {
      grid-column: 5/ -1;
    }
  }

  .c-ambassador:nth-child(even) {
    .c-ambassador__inner {
      grid-column: 5/ -1;
    }

    .c-ambassador__image {
      grid-column: 1 / 5;
    }

    .c-ambassador__heading {
      margin-left: auto;
      margin-right: $column-spacing;
    }
  }
}

.c-ambassador[aria-expanded='false'] {
  .c-ambassador__cover .o-picture__img {
    visibility: visible;
    opacity: 1;
  }

  .c-ambassador__image,
  .c-ambassador__content {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .c-ambassador__heading {
    @include highlightedHeader();

    @include bp-down(medium) {
      width: 100%;
    }
  }
}

.c-ambassador[aria-expanded='true'] {
  .c-ambassador__cover .o-picture__img {
    visibility: hidden;
    opacity: 0;
  }

  .c-ambassador__image,
  .c-ambassador__content {
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  .c-ambassador__image {
    padding-top: 125%;
    height: 0;

    .o-picture {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .o-picture__img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: $global-transition;
    }
  }

  .c-ambassador__title {
    margin-bottom: $column-spacing;

    @include highlightedHeader();

    @include bp-down(medium) {
      width: 100%;
    }
  }

  .c-ambassador__name {
    margin-bottom: $column-spacing;
  }

  .c-ambassador__name,
  .c-ambassador__content {
    padding: 0 2 * $column-spacing;
  }

  .c-ambassador__content {
    @include bp-down(medium) {
      padding-bottom: var(--section-spacing);
    }
  }
}
