/* stylelint-disable */
.c-faq {
  padding: $column-spacing 0;

  .c-section-heading {
    text-align: center;

    @include bp(xlarge) {
      text-align: left;
      align-self: start;
      grid-column: 1 / 4;
    }
  }
}

.c-faq__wrapper {
  padding: $column-spacing * 2;
}

.c-faq__items {
  @include bp(xlarge) {
    grid-column: 4 / -1;
  }
}

.c-faq__items,
.c-faq__item {
  display: flex;
  flex-direction: column;
  gap: $spacing;
  transition: $global-transition;
}

.c-faq__item:has(.c-faq__title[aria-expanded='true']) {
  margin-bottom: 50px;
}

.c-faq__title {
  position: relative;
  padding: 0 $spacing $spacing;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing;
  border-bottom: 1px solid $color-black;
  cursor: pointer;
  transition: $global-transition;

  h4 {
    @include smallcaps();

    position: relative;
    left: 0;
    transform: translateX(0);
    transition: $global-transition;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 1px;
    background-color: transparent;
    transition: $global-transition;
  }

  &::after {
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    content: '';
    flex-grow: 0;

    @include chevron();

    transform: rotate(-90deg);
    transition: $global-transition;
  }

  &[aria-expanded='true'] {
    h4 {
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      background-color: $color-black;
    }

    &::after {
      transform: rotate(0deg);
    }
  }
}

.c-faq__answer {
  --side-width: 200px;
  --gap: 5%;

  display: grid;
  grid-template-rows: 0fr;
  margin: 0;
  transition: $global-transition;

  @include bp(xlarge) {
    --side-width: 230px;
  }

  &:last-child {
    border: none;
  }

  [aria-expanded='true'] + & {
    grid-template-rows: 1fr;
    padding-bottom: $spacing * 2;
    padding-top: $spacing * 2;
    background-color: rgba(255, 255, 255, 0.15);
  }

  h1,
  h2,
  h3,
  h4,
  ol,
  ul,
  li,
  p {
    + * {
      margin-top: $spacing;
    }
  }
}

.c-faq__content {
  display: grid;
  grid-template-rows: 0fr;
  padding-left: $spacing;
  padding-right: $spacing;

  @include bp(medium) {
    width: calc(100% - var(--side-width) - var(--gap));
  }
}

.c-faq__reveal-more {
  text-align: right;
}

.c-faq__more {
  display: grid;
  grid-template-rows: 0fr;
  margin: 0;
  transition: $global-transition;

  > div {
    overflow: hidden;
  }

  [aria-expanded='true'] + & {
    grid-template-rows: 1fr;
    padding-bottom: $spacing;
    padding-top: $spacing;
  }
}

.c-faq__side {
  width: var(--side-width);
  padding: $spacing;
  margin: 0 0 0 auto;

  @include bp(medium) {
    position: sticky;
    bottom: 0;
    right: 0;
    align-self: flex-end;
  }
}

.c-faq__answer-inner {
  position: relative;
  display: flex;
  flex-direction: column;

  @include bp(medium) {
    flex-direction: row;
    justify-content: space-around;
  }

  .c-faq__item:has([aria-expanded='false']) & {
    overflow: hidden;
  }
}

.c-faq__specialist {
  padding: calc($spacing / 2);
  border: 1px solid $color-border;
  max-width: var(--side-width);
  margin-top: $spacing;

  h4 {
    padding-top: calc($spacing / 2);
    letter-spacing: 1px;
    font-size: 90%;
    text-align: center;
    text-transform: uppercase;
  }
}

.c-faq__collapse {
  display: none;
  margin: $spacing 0;

  .c-faq__answer:has([aria-expanded='true']) & {
    display: block;
  }
}
/* stylelint-enable */
