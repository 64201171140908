/* stylelint-disable */
.bg-white {
  background-color: $color-white;
}

.bg-cream_light {
  background-color: $color-light-cream;
}

.bg-cream_dark {
  background-color: $color-dark-cream;
}

.bg-purple {
  background-color: $color-purple;
}
.o-section {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}

.o-section__title {
  z-index: 1;
  margin-bottom: $spacing;

  &.negative-left {
    @include highlightedHeader();

    @include bp(medium) {
      right: calc(1.5 * var(--section-spacing));
    }

    @include bp(xlarge) {
      right: calc(2 * var(--section-spacing));
    }
  }
}

.o-section__content {
  text-align: justify;

  @include bp-down(medium) {
    padding-left: $spacing;
    padding-right: $spacing;
  }
}

.o-section__image {
  @include bp-down(medium) {
    margin-top: $row-spacing;
  }
}

.o-section__title,
.o-section__content,
.o-section__image {
  position: relative;

  @include bp-down(large) {
    place-self: start;
  }
}

.o-section:not(.o-section--about):has(.o-section__image.left) {
  .o-section__content {
    padding-right: $spacing;

    @include bp(medium) {
      grid-column: 4 / -1;
    }

    @include bp(large) {
      padding-right: 0;
      grid-column: 4 / -2;
    }

    @include bp(xlarge) {
      grid-column: 4 / 7;
    }
  }

  .o-section__image {
    @include bp(medium) {
      grid-column: 1 / 4;
      right: $column-spacing;
    }

    @include bp(large) {
      margin-top: calc(-0.5 * var(--section-spacing));
      margin-bottom: calc(-1.5 * var(--section-spacing));
    }
  }
}

.o-section:not(.o-section--about):has(.o-section__image.right) {
  .o-section__content {
    @include bp-only(medium) {
      grid-column: 1/6;

      .negative-left {
        right: $column-spacing;
      }
    }

    @include bp-only(large) {
      grid-column: 2/6;
    }

    @include bp(xlarge) {
      grid-column: 3/6;
    }
  }

  .o-section__image {
    @include bp(medium) {
      grid-column: 6 / -1;
      left: $column-spacing;
      margin-top: calc(-0.5 * var(--section-spacing));
      margin-bottom: calc(-0.5 * var(--section-spacing));
    }

    @include bp(large) {
      margin-top: calc(-1.5 * var(--section-spacing));
      margin-bottom: calc(-0.5 * var(--section-spacing));
    }
  }
}

.o-section--about {
  @include bp-down(medium) {
    > * {
      display: flex;
      flex-direction: column;
    }
  }

  .o-section__content {
    @include bp-down(medium) {
      order: 2;
    }

    @include bp-only(medium) {
      grid-column: 4 / -1;
      padding: 0 $spacing;
    }

    @include bp(large) {
      grid-column: 5 / 8;
      left: $column-spacing;
    }
  }

  .o-section__image {
    @include bp-down(medium) {
      order: 1;
      max-width: 280px;
      margin: 0 auto;
    }

    @include bp-only(medium) {
      grid-column: 1 / 4;
    }

    @include bp(large) {
      grid-column: 2 / 5;
      right: $column-spacing;
    }
  }
}
/* stylelint-enable */
