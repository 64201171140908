/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  display: block;

  @include bp(medium) {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
    grid-column-gap: $column-spacing;
    grid-row-gap: $row-spacing;
    grid-auto-flow: column;
    align-items: center;
  }
}
