/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding: $row-spacing 0;
  font-size: 0.875rem;
}

.c-footer__logo {
  max-width: 130px;

  @include bp-down(medium) {
    margin: 0 auto $spacing;
    display: block;
  }
}

.c-footer__content {
  @include bp(medium) {
    grid-column: 3 / 5;
  }

  @include bp-down(medium) {
    margin: $spacing auto;
    text-align: center;
  }
}

.c-footer__newsletter {
  @include bp(medium) {
    grid-column: 6 / -1;
  }
}

.c-footer__copyright {
  font-size: 90%;
  text-align: center;

  @include bp(medium) {
    grid-column: 1 / -1;
  }

  @include bp-down(medium) {
    display: flex;
    flex-direction: column;
  }
}

.c-footer__socials {
  display: flex;
  gap: 1rem;

  @include bp-down(medium) {
    margin-top: $spacing;
    justify-content: center;
  }

  .socials__icon {
    display: block;
    font-size: 1.4rem;

    svg {
      position: relative;
      display: inline-block;
      width: 1em;
      height: 1em;
      fill: currentColor;
      vertical-align: middle;
    }
  }
}
