@charset "UTF-8";
/*
  Project: Historieanwen
  Author: Ewa Karaszkiewicz
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* CSS variables
   ========================================================================== */
:root {
  --section-spacing: 1.5rem;
}
@media (min-width: 768px) {
  :root {
    --section-spacing: 3rem;
  }
}
@media (min-width: 1024px) {
  :root {
    --section-spacing: 5rem;
  }
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1,
h2,
h3 {
  font-family: "Cormorant", "Georgia", "Times New Roman", serif;
  font-weight: 700;
}
h1 em,
h2 em,
h3 em {
  font-style: italic;
  font-weight: 300;
  font-size: 0.625em;
  line-height: 0.9;
}

h1 {
  font-size: 3.6rem;
  font-size: clamp(2.2rem, 0.125vw + 1.6rem, 3.6rem);
}

h2 {
  font-size: 3rem;
  font-size: clamp(1.8rem, 1.8vw + 1.4rem, 3rem);
}

h3 {
  font-size: 2.44rem;
  font-size: clamp(1.5rem, 1.7vw + 1rem, 2.44rem);
}

h4,
h5,
h6 {
  font-weight: inherit;
  font-size: 1rem;
}

.c-section-heading {
  margin-bottom: 4vw;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #1e1e1e;
  font-family: "Hanken Grotesk", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.55; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
}
@media (min-width: 768px) {
  html {
    font-size: 18px;
  }
}
html *:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic; /* [2] */
  max-width: 100%; /* [1] */
  vertical-align: middle; /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #1e1e1e;
  text-decoration: underline;
}
a:hover {
  color: #fff;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%; /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
    grid-column-gap: 2vw;
    grid-row-gap: 2vh;
    grid-auto-flow: column;
    align-items: center;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* stylelint-disable */
.bg-white {
  background-color: #fff;
}

.bg-cream_light {
  background-color: #fcf7f2;
}

.bg-cream_dark {
  background-color: rgba(237, 201, 169, 0.25);
}

.bg-purple {
  background-color: #b7a1e7;
}

.o-section {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}

.o-section__title {
  z-index: 1;
  margin-bottom: 1rem;
}
.o-section__title.negative-left {
  width: fit-content;
  padding: 1rem;
  line-height: 1;
  background-color: #fff;
}
.o-section__title.negative-left:has(em) {
  padding-top: 0;
}
@media (min-width: 768px) {
  .o-section__title.negative-left {
    right: calc(1.5 * var(--section-spacing));
  }
}
@media (min-width: 1400px) {
  .o-section__title.negative-left {
    right: calc(2 * var(--section-spacing));
  }
}

.o-section__content {
  text-align: justify;
}
@media (max-width: 767.98px) {
  .o-section__content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 767.98px) {
  .o-section__image {
    margin-top: 2vh;
  }
}

.o-section__title,
.o-section__content,
.o-section__image {
  position: relative;
}
@media (max-width: 1023.98px) {
  .o-section__title,
  .o-section__content,
  .o-section__image {
    place-self: start;
  }
}

.o-section:not(.o-section--about):has(.o-section__image.left) .o-section__content {
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .o-section:not(.o-section--about):has(.o-section__image.left) .o-section__content {
    grid-column: 4/-1;
  }
}
@media (min-width: 1024px) {
  .o-section:not(.o-section--about):has(.o-section__image.left) .o-section__content {
    padding-right: 0;
    grid-column: 4/-2;
  }
}
@media (min-width: 1400px) {
  .o-section:not(.o-section--about):has(.o-section__image.left) .o-section__content {
    grid-column: 4/7;
  }
}
@media (min-width: 768px) {
  .o-section:not(.o-section--about):has(.o-section__image.left) .o-section__image {
    grid-column: 1/4;
    right: 2vw;
  }
}
@media (min-width: 1024px) {
  .o-section:not(.o-section--about):has(.o-section__image.left) .o-section__image {
    margin-top: calc(-0.5 * var(--section-spacing));
    margin-bottom: calc(-1.5 * var(--section-spacing));
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .o-section:not(.o-section--about):has(.o-section__image.right) .o-section__content {
    grid-column: 1/6;
  }
  .o-section:not(.o-section--about):has(.o-section__image.right) .o-section__content .negative-left {
    right: 2vw;
  }
}
@media (min-width: 1024px) and (max-width: 1399.98px) {
  .o-section:not(.o-section--about):has(.o-section__image.right) .o-section__content {
    grid-column: 2/6;
  }
}
@media (min-width: 1400px) {
  .o-section:not(.o-section--about):has(.o-section__image.right) .o-section__content {
    grid-column: 3/6;
  }
}
@media (min-width: 768px) {
  .o-section:not(.o-section--about):has(.o-section__image.right) .o-section__image {
    grid-column: 6/-1;
    left: 2vw;
    margin-top: calc(-0.5 * var(--section-spacing));
    margin-bottom: calc(-0.5 * var(--section-spacing));
  }
}
@media (min-width: 1024px) {
  .o-section:not(.o-section--about):has(.o-section__image.right) .o-section__image {
    margin-top: calc(-1.5 * var(--section-spacing));
    margin-bottom: calc(-0.5 * var(--section-spacing));
  }
}

@media (max-width: 767.98px) {
  .o-section--about > * {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .o-section--about .o-section__content {
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .o-section--about .o-section__content {
    grid-column: 4/-1;
    padding: 0 1rem;
  }
}
@media (min-width: 1024px) {
  .o-section--about .o-section__content {
    grid-column: 5/8;
    left: 2vw;
  }
}
@media (max-width: 767.98px) {
  .o-section--about .o-section__image {
    order: 1;
    max-width: 280px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .o-section--about .o-section__image {
    grid-column: 1/4;
  }
}
@media (min-width: 1024px) {
  .o-section--about .o-section__image {
    grid-column: 2/5;
    right: 2vw;
  }
}

/* stylelint-enable */
/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1600px + 4vw);
  padding: 0 2vw;
}

.o-wrapper--body {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.o-wrapper--body .c-main {
  flex: 1;
  overflow: hidden;
  display: contents;
}

.c-ambassadors .o-section__title {
  margin-bottom: var(--section-spacing);
  text-align: center;
  line-height: 1;
}

.c-ambassador {
  position: relative;
  width: 100%;
  transition: all 500ms ease-in-out;
  background-color: rgba(237, 201, 169, 0.25);
}
@media (min-width: 1024px) {
  .c-ambassador {
    min-height: 280px;
  }
}
.c-ambassador * {
  transition: all 500ms ease-in-out;
}

.c-ambassador__cover {
  width: 100%;
}
@media (min-width: 768px) {
  .c-ambassador__cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .c-ambassador[aria-expanded=true] .c-ambassador__cover {
    height: 0;
  }
}
.c-ambassador__cover .o-picture__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 500ms ease-in-out;
}

.c-ambassador__image {
  position: relative;
  align-self: stretch;
}

.c-ambassador__inner {
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .c-ambassador__inner {
    padding-top: var(--section-spacing);
    padding-bottom: var(--section-spacing);
  }
}

.c-ambassador__heading {
  position: relative;
  text-align: left;
}

.c-ambassador__title {
  font-style: italic;
}

.c-ambassador__name {
  font-family: "Cormorant", "Georgia", "Times New Roman", serif;
}
@media (max-width: 767.98px) {
  .c-ambassador__name {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .c-ambassador__name {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .c-ambassador:nth-child(odd) .c-ambassador__inner {
    grid-column: 1/5;
    padding-left: 2vw;
  }
  .c-ambassador:nth-child(odd) .c-ambassador__image {
    grid-column: 5/-1;
  }
  .c-ambassador:nth-child(even) .c-ambassador__inner {
    grid-column: 5/-1;
  }
  .c-ambassador:nth-child(even) .c-ambassador__image {
    grid-column: 1/5;
  }
  .c-ambassador:nth-child(even) .c-ambassador__heading {
    margin-left: auto;
    margin-right: 2vw;
  }
}
.c-ambassador[aria-expanded=false] .c-ambassador__cover .o-picture__img {
  visibility: visible;
  opacity: 1;
}
.c-ambassador[aria-expanded=false] .c-ambassador__image,
.c-ambassador[aria-expanded=false] .c-ambassador__content {
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.c-ambassador[aria-expanded=false] .c-ambassador__heading {
  width: fit-content;
  padding: 1rem;
  line-height: 1;
  background-color: #fff;
}
.c-ambassador[aria-expanded=false] .c-ambassador__heading:has(em) {
  padding-top: 0;
}
@media (max-width: 767.98px) {
  .c-ambassador[aria-expanded=false] .c-ambassador__heading {
    width: 100%;
  }
}

.c-ambassador[aria-expanded=true] .c-ambassador__cover .o-picture__img {
  visibility: hidden;
  opacity: 0;
}
.c-ambassador[aria-expanded=true] .c-ambassador__image,
.c-ambassador[aria-expanded=true] .c-ambassador__content {
  visibility: visible;
  opacity: 1;
  height: auto;
}
.c-ambassador[aria-expanded=true] .c-ambassador__image {
  padding-top: 125%;
  height: 0;
}
.c-ambassador[aria-expanded=true] .c-ambassador__image .o-picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.c-ambassador[aria-expanded=true] .c-ambassador__image .o-picture__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 500ms ease-in-out;
}
.c-ambassador[aria-expanded=true] .c-ambassador__title {
  margin-bottom: 2vw;
  width: fit-content;
  padding: 1rem;
  line-height: 1;
  background-color: #fff;
}
.c-ambassador[aria-expanded=true] .c-ambassador__title:has(em) {
  padding-top: 0;
}
@media (max-width: 767.98px) {
  .c-ambassador[aria-expanded=true] .c-ambassador__title {
    width: 100%;
  }
}
.c-ambassador[aria-expanded=true] .c-ambassador__name {
  margin-bottom: 2vw;
}
.c-ambassador[aria-expanded=true] .c-ambassador__name,
.c-ambassador[aria-expanded=true] .c-ambassador__content {
  padding: 0 4vw;
}
@media (max-width: 767.98px) {
  .c-ambassador[aria-expanded=true] .c-ambassador__content {
    padding-bottom: var(--section-spacing);
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.9rem 1.2rem;
  transition: all 500ms ease-in-out;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

/* Style variants
   ========================================================================== */
.c-btn--purple {
  border: 1px solid #510c76;
  background-color: transparent;
}
.c-btn--purple, .c-btn--purple:hover, .c-btn--purple:active, .c-btn--purple:focus {
  text-decoration: none; /* [4] */
  color: #510c76;
}
.c-btn--purple:hover, .c-btn--purple:focus {
  background-color: #fff;
}

.c-btn--faq {
  display: block;
  margin: 1rem auto 0;
  text-align: center;
}
.c-btn--faq:hover {
  color: #510c76;
}

/* Buttons section
   ========================================================================== */
.c-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
* + .c-buttons {
  margin-top: 2vw;
}
.c-buttons .c-btn {
  width: fit-content;
}

/* stylelint-disable */
.c-faq {
  padding: 2vw 0;
}
.c-faq .c-section-heading {
  text-align: center;
}
@media (min-width: 1400px) {
  .c-faq .c-section-heading {
    text-align: left;
    align-self: start;
    grid-column: 1/4;
  }
}

.c-faq__wrapper {
  padding: 4vw;
}

@media (min-width: 1400px) {
  .c-faq__items {
    grid-column: 4/-1;
  }
}

.c-faq__items,
.c-faq__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 500ms ease-in-out;
}

.c-faq__item:has(.c-faq__title[aria-expanded=true]) {
  margin-bottom: 50px;
}

.c-faq__title {
  position: relative;
  padding: 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #1e1e1e;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}
.c-faq__title h4 {
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 0.9rem;
  position: relative;
  left: 0;
  transform: translateX(0);
  transition: all 500ms ease-in-out;
}
.c-faq__title::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  height: 1px;
  background-color: transparent;
  transition: all 500ms ease-in-out;
}
.c-faq__title::after {
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  content: "";
  flex-grow: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=");
  transform: rotate(-90deg);
  transition: all 500ms ease-in-out;
}
.c-faq__title[aria-expanded=true] h4 {
  left: 50%;
  transform: translateX(-50%);
}
.c-faq__title[aria-expanded=true]::before {
  background-color: #1e1e1e;
}
.c-faq__title[aria-expanded=true]::after {
  transform: rotate(0deg);
}

.c-faq__answer {
  --side-width: 200px;
  --gap: 5%;
  display: grid;
  grid-template-rows: 0fr;
  margin: 0;
  transition: all 500ms ease-in-out;
}
@media (min-width: 1400px) {
  .c-faq__answer {
    --side-width: 230px;
  }
}
.c-faq__answer:last-child {
  border: none;
}
[aria-expanded=true] + .c-faq__answer {
  grid-template-rows: 1fr;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: rgba(255, 255, 255, 0.15);
}
.c-faq__answer h1 + *,
.c-faq__answer h2 + *,
.c-faq__answer h3 + *,
.c-faq__answer h4 + *,
.c-faq__answer ol + *,
.c-faq__answer ul + *,
.c-faq__answer li + *,
.c-faq__answer p + * {
  margin-top: 1rem;
}

.c-faq__content {
  display: grid;
  grid-template-rows: 0fr;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .c-faq__content {
    width: calc(100% - var(--side-width) - var(--gap));
  }
}

.c-faq__reveal-more {
  text-align: right;
}

.c-faq__more {
  display: grid;
  grid-template-rows: 0fr;
  margin: 0;
  transition: all 500ms ease-in-out;
}
.c-faq__more > div {
  overflow: hidden;
}
[aria-expanded=true] + .c-faq__more {
  grid-template-rows: 1fr;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.c-faq__side {
  width: var(--side-width);
  padding: 1rem;
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .c-faq__side {
    position: sticky;
    bottom: 0;
    right: 0;
    align-self: flex-end;
  }
}

.c-faq__answer-inner {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .c-faq__answer-inner {
    flex-direction: row;
    justify-content: space-around;
  }
}
.c-faq__item:has([aria-expanded=false]) .c-faq__answer-inner {
  overflow: hidden;
}

.c-faq__specialist {
  padding: 0.5rem;
  border: 1px solid #b7a1e7;
  max-width: var(--side-width);
  margin-top: 1rem;
}
.c-faq__specialist h4 {
  padding-top: 0.5rem;
  letter-spacing: 1px;
  font-size: 90%;
  text-align: center;
  text-transform: uppercase;
}

.c-faq__collapse {
  display: none;
  margin: 1rem 0;
}
.c-faq__answer:has([aria-expanded=true]) .c-faq__collapse {
  display: block;
}

/* stylelint-enable */
/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  padding: 2vh 0;
  font-size: 0.875rem;
}

.c-footer__logo {
  max-width: 130px;
}
@media (max-width: 767.98px) {
  .c-footer__logo {
    margin: 0 auto 1rem;
    display: block;
  }
}

@media (min-width: 768px) {
  .c-footer__content {
    grid-column: 3/5;
  }
}
@media (max-width: 767.98px) {
  .c-footer__content {
    margin: 1rem auto;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .c-footer__newsletter {
    grid-column: 6/-1;
  }
}

.c-footer__copyright {
  font-size: 90%;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__copyright {
    grid-column: 1/-1;
  }
}
@media (max-width: 767.98px) {
  .c-footer__copyright {
    display: flex;
    flex-direction: column;
  }
}

.c-footer__socials {
  display: flex;
  gap: 1rem;
}
@media (max-width: 767.98px) {
  .c-footer__socials {
    margin-top: 1rem;
    justify-content: center;
  }
}
.c-footer__socials .socials__icon {
  display: block;
  font-size: 1.4rem;
}
.c-footer__socials .socials__icon svg {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 1rem 0;
  transition: all 500ms ease-in-out;
}
.c-header > .o-layout {
  align-items: center;
}

.c-header.is-scrolled {
  padding: 12px 0;
  background-color: rgba(255, 255, 255, 0.95);
}

.c-header::before {
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.c-header .o-logo {
  grid-column: 1/6;
  max-width: 400px;
}
.c-header .o-logo .o-logo__image {
  display: none;
}
@media (min-width: 768px) {
  .c-header .o-logo .o-logo__image--desktop {
    display: block;
    height: 80px;
  }
}
@media (max-width: 767.98px) {
  .c-header .o-logo .o-logo__image--mobile {
    display: block;
    height: 26px;
  }
}
@media (max-width: 479.98px) {
  .c-header .o-logo .o-logo__image--mobile {
    height: 20px;
  }
}

/**
 * Hamburger menu
 */
.c-header__burger {
  position: absolute;
  top: 50%;
  right: 1rem;
  z-index: 550;
  transition: all 500ms ease-in-out;
  transform: translateY(-50%);
  display: none;
  border: 0;
  background: none;
}
@media (max-width: 1023.98px) {
  .c-header__burger {
    display: block;
  }
}

.c-header__burger-line {
  width: 30px;
  height: 2px;
  background-color: #1e1e1e;
  display: none;
  margin: 6px auto;
  transition: all 500ms ease-in-out;
}
@media (max-width: 1023.98px) {
  .c-header__burger-line {
    display: block;
  }
}
.c-header__burger.is-clicked .c-header__burger-line:nth-child(2) {
  opacity: 0;
}
.c-header__burger.is-clicked .c-header__burger-line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.c-header__burger.is-clicked .c-header__burger-line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media (min-width: 768px) {
  .c-header__navigation {
    grid-column: -1;
  }
}
@media (max-width: 1023.98px) {
  .c-header__navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 500ms ease-in-out;
  }
  .nav-is-open .c-header__navigation {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 1;
    overflow: auto;
  }
}

.c-hero,
.c-hero .o-wrapper {
  position: relative;
}

.c-hero__content {
  position: relative;
  margin-top: -2rem;
  text-align: center;
  width: fit-content;
  padding: 1rem;
  line-height: 1;
  background-color: #fff;
}
.c-hero__content:has(em) {
  padding-top: 0;
}
@media (max-width: 767.98px) {
  .c-hero__content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .c-hero__content {
    position: absolute;
    width: fit-content;
    right: 2vw;
    bottom: 4vw;
    text-align: right;
  }
}

.c-hero__slider {
  position: relative;
}

.c-hero__slide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 85%;
}
@media (min-width: 1024px) {
  .c-hero__slide {
    padding-top: 85vh;
  }
}
@media (min-width: 1400px) {
  .c-hero__slide {
    padding-top: 80vh;
  }
}
@media (min-width: 1600px) {
  .c-hero__slide {
    padding-top: 70vh;
  }
}
.c-hero__slide .c-hero__slide-picture,
.c-hero__slide .c-hero__slide-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0 0 2em 0;
}
@media (max-width: 1023.98px) {
  .c-main-nav {
    flex-direction: column;
    text-align: center;
  }
}

.c-main-nav__item {
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 0.9rem;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
}

.c-main-nav__link {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  color: #510c76;
}
.c-main-nav__link:hover, .c-main-nav__link:focus {
  color: #510c76;
  text-decoration: underline;
}

.current-menu-item .c-main-nav__link {
  color: red;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}