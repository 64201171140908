/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: $spacing 0;
  transition: $global-transition;

  > .o-layout {
    align-items: center;
  }
}

.c-header.is-scrolled {
  padding: 12px 0;
  background-color: rgba(255, 255, 255, 0.95);
}

.c-header::before {
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: ;
  z-index: -1;
}

.c-header .o-logo {
  grid-column: 1 / 6;
  max-width: 400px;

  .o-logo__image {
    display: none;
  }

  .o-logo__image--desktop {
    @include bp(medium) {
      display: block;
      height: 80px;
    }
  }

  .o-logo__image--mobile {
    @include bp-down(medium) {
      display: block;
      height: 26px;
    }

    @include bp-down(small) {
      height: 20px;
    }
  }
}

/**
 * Hamburger menu
 */

.c-header__burger {
  position: absolute;
  top: 50%;
  right: $spacing;
  z-index: 550;
  transition: $global-transition;
  transform: translateY(-50%);
  display: none;
  border: 0;
  background: none;

  @include bp-down(large) {
    display: block;
  }
}

.c-header__burger-line {
  width: 30px;
  height: 2px;
  background-color: $color-black;
  display: none;
  margin: 6px auto;
  transition: $global-transition;

  @include bp-down(large) {
    display: block;
  }

  .c-header__burger.is-clicked & {
    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    &:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

// Mobile navigation
.c-header__navigation {
  @include bp(medium) {
    grid-column: -1;
  }

  @include bp-down(large) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: $global-transition;

    .nav-is-open & {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      z-index: 1;
      overflow: auto;
    }
  }
}
