.c-hero,
.c-hero .o-wrapper {
  position: relative;
}

.c-hero__content {
  position: relative;
  margin-top: calc(-2 * $spacing);
  text-align: center;

  @include highlightedHeader();

  @include bp-down(medium) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  @include bp(medium) {
    position: absolute;
    width: fit-content;
    right: $column-spacing;
    bottom: 2 * $column-spacing;
    text-align: right;
  }
}

.c-hero__slider {
  position: relative;
}

.c-hero__slide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 85%;

  @include bp(large) {
    padding-top: 85vh;
  }

  @include bp(xlarge) {
    padding-top: 80vh;
  }

  @include bp(full) {
    padding-top: 70vh;
  }

  .c-hero__slide-picture,
  .c-hero__slide-img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
