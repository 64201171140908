/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  // @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: calc(1600px + 2 * $column-spacing);
  padding: 0 $column-spacing;
}

.o-wrapper--body {
  position: relative;

  // Sticky footer settings:
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .c-main {
    flex: 1;
    overflow: hidden;
    display: contents;
  }
}
