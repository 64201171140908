// ==========================================================================
// #CLEARFIX
// ==========================================================================

// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .usage {
//   @include clearfix();
// }

@mixin smallcaps() {
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

@mixin highlightedHeader() {
  width: fit-content;
  padding: $spacing;
  line-height: 1;
  background-color: $color-white;

  &:has(em) {
    padding-top: 0;
  }
}
