/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  margin: 0 0 2em 0;

  @include bp-down(large) {
    flex-direction: column;
    text-align: center;
  }
}

.c-main-nav__item {
  @include smallcaps();

  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
}

.c-main-nav__link {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  color: $color-purple-dark;

  &:hover,
  &:focus {
    color: $color-purple-dark;
    text-decoration: underline;
  }
}

.current-menu-item .c-main-nav__link {
  color: red;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;

  .c-main-nav__item:hover & {
    display: flex;
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

// .current-menu-item .c-main-nav__sublink {
// }
